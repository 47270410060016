import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';

const PlainText = ({ body, size }) => <Text size={size}>{body}</Text>;

PlainText.propTypes = {
  body: PropTypes.string,
  size: PropTypes.string,
};

export default PlainText;
